import React from "react";
export interface User {
    name: string;
    lastname: string;
    email: string;
    cards: any;
    dui: any;
    phone: any;
}

export interface ContextModel {
    user: any;
    event: any;
    order: any;
    config: any;
    asignateOrderData: (order: any) => void;
    asignateEventData: (event: any) => void;
    asignateUser: (user: any) => void;
    asignateConfig: (config: any) => void;
    // loginUser:(email:string, password:string) => void
}

const UserContextProvider = React.createContext<ContextModel>({
    user: {
        name: "",
        lastname: "",
        email: "",
        cards: [],
        dui: "",
        phone:"",
        photo:"",
        ticketsBanner:"",
    },
    config: { countryCode: "" },
    event: {},
    order: {},
    asignateOrderData: (order: any) => { },
    asignateEventData: (event: any) => { },
    asignateUser: (user: any) => {},
    asignateConfig: (config: any) => { }
    // loginUser:(email:string, password:string) => {},

});

export default UserContextProvider;