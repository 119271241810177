
import React, { useState } from "react";
import UserContextProvider, { ContextModel, User } from "../interface/interfaces";

// import { login } from "../service/auth";


interface Props {
    children: React.ReactNode;
}

const Context: React.FC<Props> = (props) => {
    const [user, setUser] = useState<any>({
        id: 0,
        name: "",
        lastname: "",
        email: "",
        cards: [],
        dui: "",
        phone:"",
        photo:""
    });

    const [event, setEvent] = useState<any>({

    })

    const [order, setOrder] = useState<any>({

    })

    const [config, setConfig] = useState<any>({
        countryCode : "SV",//"RAF",
        storeKey:"SJ5b3V85JVtYZIfd8dvx"
        //SV:SJ5b3V85JVtYZIfd8dvx
        //RAF:bIWIyB4Wo48E48QAxW3S
    })

    const asignateEventData = (event: any) => {
        setEvent(event);
    };

    const asignateUser = (user: User) => {
        setUser(user);
    };

    const asignateOrderData = (order: any) => {
        setOrder(order);
    };

    const asignateConfig = (config: any) => {
        setConfig(config);
    }

    /*     const loginUser = async (email: string, password: string) => {
            return await login(email, password)
        } */



    const contextValue: ContextModel = {
        user,
        event,
        order,
        config,
        asignateOrderData,
        asignateEventData,
        asignateUser,
        asignateConfig,
        //loginUser,
    };

    return (
        <UserContextProvider.Provider value={contextValue}>
            {props.children}
        </UserContextProvider.Provider>
    )
};

export default Context;